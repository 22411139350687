import { addOrEditItem, deleteItem, getItems } from '@/store/api';

export default {
    namespaced: true,
    state: {
        onboardingInfo: {},
        onboardings: [],
        preSelectedSolarProject: null,
        savedSolarProjects: [],
        yourSolarProjects: [],
    },
    mutations: {
        updateOnboardings(state, onboardings) {
            state.onboardings = onboardings || [];
        },
        updatePreSelectedSolarProject(state, preSelectedSolarProject) {
            state.preSelectedSolarProject = preSelectedSolarProject;
        },
        updateSavedSolarProjects(state, savedSolarProjects) {
            state.savedSolarProjects = savedSolarProjects;
        },
        updateYourSolarProjects(state, yourSolarProjects) {
            state.yourSolarProjects = yourSolarProjects;
        },
    },
    getters: {
        onboardings: state => {
            return state.onboardings || [];
        },
        pre_selected_solar_project: state => {
            return state.preSelectedSolarProject || null;
        },
        saved_solar_projects: state => {
            return state.savedSolarProjects || [];
        },
        your_solar_projects: state => {
            return state.yourSolarProjects || [];
        },
    },
    actions: {
        getOnboardings: getItems('onboardings/'),
        deleteOnboarding: deleteItem('onboardings/'),
        createOnboarding: addOrEditItem('onboardings/'),
        createOnboardingAdditionalDataItem: addOrEditItem( 'additional-data/', 'onboardings/'),
        createOnboardingDocumentItem: addOrEditItem('document/', 'onboardings/'),
        deleteOnboardingDocumentItem: deleteItem('document/', 'onboardings/'),
        createOnboardingGeneratedDocumentItem: addOrEditItem('generated-document/', 'onboardings/'),
        createOnboardingProfileDataItem: addOrEditItem('profile-data/', 'onboardings/'),
        createOnboardingRelationshipsDataItem: addOrEditItem('relationships-data/', 'onboardings/'),
        getOnboardingTrustAgreements: getItems('generated-trust-agreements/', 'onboardings/'),
    },
};
