export const debug = process.env.NODE_ENV !== 'production';
export const PRODUCTION = process.env.VUE_APP_ENVIRONMENT === 'production';

export const BUGSNAG_API_KEY = process.env.VUE_APP_BUGSNAG_API_KEY;
export const INTERCOM_APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
export const IPINFO_IO_TOKEN = process.env.VUE_APP_IPINFO_IO_TOKEN;
export const STRIPE_SOLAR_ACTIVITIES_LOG_ACCESS_PLAN_ID =
    process.env.VUE_APP_STRIPE_SOLAR_ACTIVITIES_LOG_ACCESS_PLAN_ID;
export const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
export const VUEX_KEY = process.env.VUE_APP_VUEX_KEY;
export const YOUTUBE_API_KEY = process.env.VUE_APP_YOUTUBE_API_KEY;

export const handleWebpackError = () => {
    if (!debug) {
        window.location.reload();
    } else {
        console.log('On production, the window would reload now.');
    }
};
